import React from "react"

// isSubscribed doesn't work as intended and react reports a memory leak... not sure how to fix...
export const useLoading = () => {
    const [error, setError] = React.useState("")
    const [loading, setLoading] = React.useState(false)

    const action = async (func: () => Promise<void>) => {
        let isSubscribed = true
        try {
            setError("")
            setLoading(true)
            await func()
        } catch (e) {
            console.log({ e })
            if (isSubscribed) {
                setError(e.message ?? e.code ?? e.error)
            }
        } finally {
            if (isSubscribed) {
                setLoading(false)
            }
        }
        return () => (isSubscribed = false)
    }

    return { error, loading, action }
}

import { capitalizeDisplayName, Captcha, Client, firebasePhoneFormat, getCaptchaElement, PortalRole, UserStatus } from "@qlarity/common"
import * as firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import LogRocket from "logrocket"
import { action, observable } from "mobx"
import { Store } from "store/Store"
import Base from "./Base"

const AUTH_URL = process.env.REACT_APP_FIRE_LOCAL
    ? {
          VERIFY_PHONE: "http://localhost:5001/qlarity-prod/us-central1/auth_rest_verify_phone",
          VERIFY_CODE_LOGIN: "http://localhost:5001/qlarity-prod/us-central1/auth_rest_verify_code_login",
          CREATE_NEW_PORTAL_USER: "http://localhost:5001/qlarity-prod/us-central1/auth_rest_create_new_portal_user",
          TOGGLE_PORTAL_USER_ROLE: "http://localhost:5001/qlarity-prod/us-central1/auth_rest_toggle_portal_user_role",
          INVITE_CLIENT: "http://localhost:5001/qlarity-prod/us-central1/auth_rest_invite_client",
          REVOKE_REFRESH_TOKENS: "http://localhost:5001/qlarity-prod/us-central1/auth_revoke_refresh_tokens",
      }
    : {
          VERIFY_PHONE: "https://us-central1-qlarity-prod.cloudfunctions.net/auth_rest_verify_phone",
          VERIFY_CODE_LOGIN: "https://us-central1-qlarity-prod.cloudfunctions.net/auth_rest_verify_code_login",
          CREATE_NEW_PORTAL_USER: "https://us-central1-qlarity-prod.cloudfunctions.net/auth_rest_create_new_portal_user",
          TOGGLE_PORTAL_USER_ROLE: "https://us-central1-qlarity-prod.cloudfunctions.net/auth_rest_toggle_portal_user_role",
          INVITE_CLIENT: "https://us-central1-qlarity-prod.cloudfunctions.net/auth_rest_invite_client",
          REVOKE_REFRESH_TOKENS: "https://us-central1-qlarity-prod.cloudfunctions.net/auth_revoke_refresh_tokens",
      }

export interface Org {
    id: string
    city: string
    state: string
    createdOn: firebase.firestore.Timestamp
    orgName: string
    clinicians: string[]
    clients: string[]
    status: UserStatus.ACTIVE | UserStatus.ARCHIVED
}

export interface Clinician {
    id: string
    displayName: string
    clients?: string[]
    role: PortalRole
    status: UserStatus
    email: string
    orgId: string
    appPhoneNumber?: string
}

let unsubFn: (() => void) | undefined
export default class Auth extends Base {
    @observable
    portalUser?: Clinician

    @observable
    authUser?: firebase.User | null

    @observable
    isLoggedIn?: boolean

    @observable
    verifiedPhone?: string

    @observable
    role?: PortalRole

    @observable
    captcha?: Captcha

    @observable
    sessionInfo?: string

    private orgConverter: firebase.firestore.FirestoreDataConverter<Org> = {
        toFirestore(org: Org): firebase.firestore.DocumentData {
            const { id, ...rest } = org
            return { ...rest }
        },
        fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions): Org {
            const data = snapshot.data(options)
            const { city, state, createdOn, clinicians, orgName, clients, status } = data
            return { city, state, createdOn, clinicians, orgName, clients, status, id: snapshot.id }
        },
    }

    @observable
    orgCollection = firebase.firestore().collection("orgs").withConverter(this.orgConverter)

    private clinicianConverter: firebase.firestore.FirestoreDataConverter<Clinician> = {
        toFirestore(clinician: Clinician): firebase.firestore.DocumentData {
            const { id, ...rest } = clinician
            return { ...rest }
        },
        fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions): Clinician {
            const data = snapshot.data(options)
            const { displayName, role, clients, status, email, orgId, appPhoneNumber } = data
            return { displayName, role, clients, status, email, orgId, appPhoneNumber, id: snapshot.id }
        },
    }
    @observable
    clinicianCollection = firebase.firestore().collection("portalUsers").withConverter(this.clinicianConverter)

    private clientConverter: firebase.firestore.FirestoreDataConverter<Client> = {
        toFirestore(client: Client): firebase.firestore.DocumentData {
            return client
        },
        fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions): Client {
            return snapshot.data(options) as Client
        },
    }
    @observable
    clientCollection = firebase.firestore().collection("clients").withConverter(this.clientConverter)

    init = async (store: Store): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            firebase.auth().onIdTokenChanged(async (authUser) => {
                this.authUser = authUser
                const idToken = await firebase.auth().currentUser?.getIdTokenResult()
                // twoFactor claim comes from verifyCodeAndLogin JWT only
                this.isLoggedIn = idToken?.claims.twoFactor
                // Should be rolled up in to a user object when it can be changed by a user
                this.verifiedPhone = idToken?.claims.verifiedPhone
                // Role set with token because it is faster. this.PortalUser.role is too slow and causes undefined errors
                this.role = idToken?.claims.role

                // set logrocket once with uid and prevent repeat identity calls
                if (!this.portalUser && this.authUser) {
                    LogRocket.identify(this.authUser.uid)
                }

                if (this.isLoggedIn) {
                    unsubFn = this.clinicianCollection.doc(authUser?.uid).onSnapshot((snap) => (this.portalUser = snap.data()))
                } else {
                    unsubFn?.()
                }
                resolve()
            })
        })
    }
    verifyEmailPassword = async (email: string, password: string) => {
        await firebase.auth().signInWithEmailAndPassword(email, password)
    }

    authedFetch = async (url: string, body: object) => {
        if (!this.authUser) {
            throw new Error("Cannot fetch if user is not logged in")
        }
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify({
                ...body,
                idToken: await this.authUser?.getIdToken(),
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
        const json = await response.json()
        if (!response.ok) {
            console.log(json)
            throw json.errors?.[0] || json || new Error("fetch error")
        }
        return json
    }

    sendSMS = async (phoneNumber: string, existingElementId?: string, onCaptchaVerified?: () => void, isNewUser?: boolean) => {
        const formattedNumber = firebasePhoneFormat(phoneNumber)
        if (!isNewUser && this.verifiedPhone !== formattedNumber) {
            const error = { message: "Phone number doesn't match number on record. Please try again" }
            throw error
        }
        // disable recaptcha for whitelisted test number
        firebase.auth().settings.appVerificationDisabledForTesting = formattedNumber === "+15415555555"
        const element = getCaptchaElement(existingElementId || "firebase-recaptcha-container")
        this.captcha = {
            element,
            verifier: new firebase.auth.RecaptchaVerifier(element.id, { size: "small" }),
        }
        const recaptchaToken = await this.captcha.verifier.verify()
        const response = await this.authedFetch(AUTH_URL.VERIFY_PHONE, {
            phoneNumber: formattedNumber,
            recaptchaToken,
        })
        this.sessionInfo = response.sessionInfo
        if (onCaptchaVerified) {
            onCaptchaVerified()
        }
    }

    verifyCodeAndLogin = async (code: string) => {
        const response = await this.authedFetch(AUTH_URL.VERIFY_CODE_LOGIN, {
            code,
            sessionInfo: this.sessionInfo,
        })
        await firebase.auth().signInWithCustomToken(response.token)
    }

    checkDupOrg = async (orgName: string) => {
        const orgList = (await this.orgCollection.get()).docs.map((org) => org.data().orgName.toLowerCase())
        if (orgList.includes(orgName.toLowerCase())) {
            // eslint-disable-next-line no-throw-literal
            throw { message: "Organization name already exists. Please enter a different name" }
        }
    }

    createOrg = async (orgName: string, city: string, state: string, orgAdminEmail?: string, orgDisplayName?: string) => {
        await this.checkDupOrg(orgName)
        const newOrg = this.orgCollection.doc()
        await newOrg.set({
            id: newOrg.id,
            orgName,
            city,
            state,
            clinicians: [],
            createdOn: firebase.firestore.Timestamp.now(),
            clients: [],
            status: UserStatus.ACTIVE,
        })
        if (orgAdminEmail) {
            await this.invitePortalUser(orgAdminEmail, newOrg.id, PortalRole.ORG_ADMIN, orgDisplayName)
        }
        // maybe add toast in the future?
    }

    editOrg = async (orgId: string, orgName: string, city: string, state: string) => {
        await this.checkDupOrg(orgName)
        return this.orgCollection.doc(orgId).update({ id: orgId, orgName, city, state })
    }

    archiveOrg = async (orgId: string) => {
        // revoke refresh tokens of all clinicians in org so it logs them out.
        // org status is checked upon log in on server
        // TODO: Revoke client token
        const users = await this.clinicianCollection.where("orgId", "==", orgId).get()
        const uids = users.docs.map((user) => user.data().id)
        await this.authedFetch(AUTH_URL.REVOKE_REFRESH_TOKENS, { uids })
        await this.orgCollection.doc(orgId).update({ status: UserStatus.ARCHIVED })
        // maybe add toast in the future?
    }

    reactivateOrg = async (orgId: string) => {
        await this.orgCollection.doc(orgId).update({ status: UserStatus.ACTIVE })
        return
    }

    invitePortalUser = async (email: string, orgId: string, role: PortalRole, displayName?: string) => {
        const response = await this.authedFetch(AUTH_URL.CREATE_NEW_PORTAL_USER, {
            email,
            orgId,
            displayName,
            role,
        })
        console.log({ response })
        return response as { success: boolean | undefined; newUserId: string }
        // maybe add toast in the future?
    }

    inviteGlobalAdmin = async (displayName: string, email: string) => {
        const role = PortalRole.ADMIN
        const orgId = "admin"
        await this.invitePortalUser(email, orgId, role, displayName)
    }
    signInWithEmailLink = async (email: string) => {
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            await firebase.auth().signInWithEmailLink(email)
        } else {
            throw new Error("Not a valid sign in with email link")
        }
    }
    updateDisplayName = async (displayName: string) => {
        const capitalizedName = capitalizeDisplayName(displayName)

        await firebase.auth().currentUser?.updateProfile({ displayName: capitalizedName })
        await this.clinicianCollection.doc(this.authUser?.uid).update({ displayName: capitalizedName })
        const clients = await this.clientCollection.where("clinicianId", "==", this.authUser?.uid).get()
        const batch = firebase.firestore().batch()
        clients.forEach((client) => batch.update(client.ref, { clinicianName: capitalizedName }))
        batch.commit()
    }

    updatePassword = async (password: string) => {
        await firebase.auth().currentUser?.updatePassword(password)
    }

    archiveUserReassignClients = async (user: Clinician, newUserId?: string, clients?: Client[]) => {
        // transfer all clients to new user
        const oldClinicianRef = this.clinicianCollection.doc(user.id)
        // Transfer any client with the oldClincian id
        const oldClients = (await oldClinicianRef.get()).data()?.clients
        const newClinician = newUserId && (await this.clinicianCollection.doc(newUserId).get())
        await Promise.all([
            this.authedFetch(AUTH_URL.REVOKE_REFRESH_TOKENS, { uids: [user.id] }),
            clients?.map((client) => {
                if (newClinician) {
                    this.clientCollection.doc(client.caseId).update({ clinicianId: newClinician.id, clinicianName: newClinician.data()?.displayName })
                }
            }),
            oldClients &&
                oldClients.length > 0 &&
                newUserId &&
                this.clinicianCollection.doc(newUserId).update({ clients: firebase.firestore.FieldValue.arrayUnion(...oldClients) }),
        ])
        // remove from active list in org
        await this.orgCollection.doc(user.orgId).update({ clinicians: firebase.firestore.FieldValue.arrayRemove(user.id) })
        // archive user
        await oldClinicianRef.update({ status: UserStatus.ARCHIVED, clients: [] })
    }

    reactivateUser = async (user: Clinician) => {
        await this.orgCollection.doc(user.orgId).update({ clinicians: firebase.firestore.FieldValue.arrayUnion(user.id) })
        return this.clinicianCollection.doc(user.id).update({ status: UserStatus.ACTIVE })
    }

    togglePortalUserOrgAdmin = async (userId: string, currentRole: PortalRole) => {
        this.authedFetch(AUTH_URL.TOGGLE_PORTAL_USER_ROLE, {
            userId,
            role: currentRole,
        })
    }

    inviteClient = async (unformattedNumber: string, displayName: string, clinician: Clinician, caseId?: string, unlocked?: boolean): Promise<void> => {
        console.log("send user invite")
        await this.authedFetch(AUTH_URL.INVITE_CLIENT, {
            phoneNumber: firebasePhoneFormat(unformattedNumber),
            displayName,
            clinicianId: clinician.id,
            orgId: clinician.orgId,
            clinicianName: clinician.displayName,
            caseId,
            unlocked,
        })
    }

    updateClinicianAppPhoneNumber = async (unformattedNumber: string) => {
        // if the phone number is blank, the unlocked client is deleted
        if (unformattedNumber === "" && this.portalUser?.appPhoneNumber) {
            const appAccount = await this.clientCollection
                .where("clinicianId", "==", this.authUser?.uid)
                .where("phoneNumber", "==", this.portalUser?.appPhoneNumber)
                .get()
            if (appAccount.docs.length > 1) {
                throw new Error("more than account with clinician number to delete")
            }
            if (appAccount.empty) {
                throw new Error("account never existed in the first place")
            }
            await this.clientCollection.doc(appAccount.docs[0].id).delete()
            await this.clinicianCollection.doc(this.authUser?.uid).update({ appPhoneNumber: "" })
            return
        }
        if (this.portalUser) {
            await this.inviteClient(unformattedNumber, `${this.portalUser.displayName}_UNLOCKED_ACCOUNT`, this.portalUser, undefined, true)
            await this.clinicianCollection.doc(this.portalUser.id).update({ appPhoneNumber: firebasePhoneFormat(unformattedNumber) })
        }
    }

    archiveClient = async (client: Client, clinician: Clinician) => {
        await this.clinicianCollection.doc(clinician.id).update({ clients: firebase.firestore.FieldValue.arrayRemove(client.caseId) })
        await this.orgCollection.doc(client.orgId).update({ clients: firebase.firestore.FieldValue.arrayRemove(client.caseId) })
        await this.clientCollection.doc(client.caseId).update({ status: UserStatus.ARCHIVED })
    }
    reactivateClient = async (client: Client, clinician: Clinician) => {
        await this.clinicianCollection.doc(clinician.id).update({ clients: firebase.firestore.FieldValue.arrayUnion(client.caseId) })
        await this.orgCollection.doc(client.orgId).update({ clients: firebase.firestore.FieldValue.arrayUnion(client.caseId) })
        await this.clientCollection.doc(client.caseId).update({ status: UserStatus.ACTIVE })
    }

    @action
    redeemPoints = (client: Client, points: number) => {
        if (!client.points) {
            return
        }
        const p = Math.max(client.points - points, 0)
        return this.clientCollection.doc(client.caseId).update({ points: p })
    }

    @observable
    private currentOrg: Partial<Org> = {
        id: "",
        orgName: "",
        status: undefined,
    }

    getOrg = (orgId?: string) => {
        if (orgId !== this.currentOrg.id && orgId) {
            this.currentOrg.id = orgId
            this.orgCollection
                .doc(orgId)
                .onSnapshot((org) => (this.currentOrg = { id: org.id, orgName: org.data()?.orgName ?? "", status: org.data()?.status }))
        }
        return this.currentOrg
    }

    @action
    logout = async () => {
        firebase.auth().signOut()
        this.authUser = undefined
    }
}

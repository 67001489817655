// *** Experimental ***

import { makeStyles, MenuItem, TextField } from "@material-ui/core"
import { UserStatus } from "@qlarity/common"
import clsx from "clsx"
import React from "react"

export enum FilterOptions {
    NOT_ARCHIVED = "Not Archived",
    ARCHIVED = "Archived",
    ACTIVE = "Active",
    PENDING = "Pending",
}
const useStyles = makeStyles((theme) => ({
    filter: {
        justifySelf: "flex-end",
        minWidth: 130,
    },
}))

export interface CommonUserProps {
    status: UserStatus
    displayName?: string
    orgName?: string
}

export const useFilterMenu = <T extends CommonUserProps>(allUsers?: T[]): { FilterMenu: React.FC<{ className?: string }>; filteredUsers?: T[] } => {
    const classes = useStyles()
    const [filteredUsers, setFilteredUsers] = React.useState(allUsers)
    const [filterOption, setFilterOption] = React.useState<FilterOptions>(FilterOptions.NOT_ARCHIVED)

    React.useEffect(() => {
        switch (filterOption) {
            case FilterOptions.NOT_ARCHIVED:
                setFilteredUsers(allUsers?.filter((user) => user?.status === UserStatus.ACTIVE || user?.status === UserStatus.PENDING))
                break
            case FilterOptions.ACTIVE:
                setFilteredUsers(allUsers?.filter((user) => user?.status === UserStatus.ACTIVE))
                break
            case FilterOptions.PENDING:
                setFilteredUsers(allUsers?.filter((user) => user?.status === UserStatus.PENDING))
                break
            case FilterOptions.ARCHIVED:
                setFilteredUsers(allUsers?.filter((user) => user?.status === UserStatus.ARCHIVED))
                break
            default:
                break
        }
    }, [allUsers, filterOption])

    return {
        FilterMenu: ({ className }) => (
            <TextField
                id="table-select"
                select
                label="Filter"
                value={filterOption}
                SelectProps={{ MenuProps: { PaperProps: { square: true } } }}
                onChange={(e) => setFilterOption(e.target.value as FilterOptions)}
                className={clsx(classes.filter, className)}
            >
                <MenuItem value={FilterOptions.NOT_ARCHIVED}>{FilterOptions.NOT_ARCHIVED}</MenuItem>
                <MenuItem value={FilterOptions.ACTIVE}>{FilterOptions.ACTIVE}</MenuItem>
                <MenuItem value={FilterOptions.PENDING}>{FilterOptions.PENDING}</MenuItem>
                <MenuItem value={FilterOptions.ARCHIVED}>{FilterOptions.ARCHIVED}</MenuItem>
            </TextField>
        ),
        // Sort users as well
        filteredUsers: filteredUsers?.sort((a, b) => {
            if (a.displayName && b.displayName) {
                return a.displayName.localeCompare(b.displayName)
            } else if (a.orgName && b.orgName) {
                return a.orgName.localeCompare(b.orgName)
            } else {
                return 0
            }
        }),
    }
}

// export default observer(useFilterMenu)

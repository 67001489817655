import { Button, Dialog, makeStyles, TextField, Typography } from "@material-ui/core"
import { CloseButton } from "components/atoms/CloseButton"
import { observable } from "mobx"
import { observer } from "mobx-react"
import React from "react"
import Store from "store/Store"
import PortalForm, { PortalSubmitButton } from "../PortalForm"
class CreateOrgState {
    @observable
    componentName = "CreateOrg"

    @observable
    orgName = ""

    @observable
    city = ""

    @observable
    state = ""

    @observable
    orgEmail = ""

    @observable
    orgDisplayName = ""

    @observable
    createOrgOpen = false
}

const useStyles = makeStyles((theme) => ({
    inherit: {
        textAlign: "inherit",
        fontWeight: "inherit",
        fontSize: "inherit",
        padding: 0,
        width: "100%",
        justifyContent: "flex-start",
    },
}))

const CreateOrg: React.FC<{ isEdit?: boolean; orgId?: string }> = ({ isEdit, orgId }) => {
    const classes = useStyles()
    const state = React.useRef(new CreateOrgState()).current

    const toggleCreateOrg = () => {
        if (!isEdit) {
            state.orgName = ""
            state.city = ""
            state.state = ""
            state.orgDisplayName = ""
            state.orgEmail = ""
        }
        state.createOrgOpen = !state.createOrgOpen
    }

    const createOrg = async () => {
        if (isEdit && orgId) {
            await Store.auth.editOrg(orgId, state.orgName, state.city, state.state)
        } else {
            await Store.auth.createOrg(state.orgName, state.city, state.state, state.orgEmail, state.orgDisplayName)
        }
        toggleCreateOrg()
    }

    React.useEffect(() => {
        let active = true
        const fillInOrg = async () => {
            const currentOrg = (await Store.auth.orgCollection.doc(orgId).get()).data()
            if (active) {
                state.orgName = currentOrg?.orgName ?? ""
                state.city = currentOrg?.city ?? ""
                state.state = currentOrg?.state ?? ""
            }
        }
        if (isEdit && orgId) {
            fillInOrg()
        }
        return () => {
            active = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, orgId])

    return (
        <>
            <Button
                onClick={() => toggleCreateOrg()}
                color={isEdit ? "default" : "secondary"}
                variant={isEdit ? "text" : "contained"}
                className={isEdit ? classes.inherit : ""}
            >
                {isEdit ? "Edit Organization" : "Create Organization"}
            </Button>

            <Dialog open={state.createOrgOpen} onClose={toggleCreateOrg}>
                <PortalForm
                    title={isEdit ? "Edit Organization" : "Create Organization"}
                    subtitle="Input the organization details"
                    form={
                        <>
                            <TextField label="Organization Name" value={state.orgName} onChange={(e) => (state.orgName = e.target.value)} autoFocus required />
                            <TextField label="City" value={state.city} onChange={(e) => (state.city = e.target.value)} required />
                            <TextField label="State" value={state.state} onChange={(e) => (state.state = e.target.value)} required />
                            {!isEdit && (
                                <>
                                    <Typography>
                                        Enter the email address and name of the Organization Admin. This person will be allowed to add clinicians to their
                                        Organization and view their details.
                                    </Typography>
                                    <TextField
                                        label="Name of new org admin"
                                        value={state.orgDisplayName}
                                        onChange={(e) => (state.orgDisplayName = e.target.value)}
                                    />
                                    <TextField label="Email of new org admin" value={state.orgEmail} onChange={(e) => (state.orgEmail = e.target.value)} />
                                </>
                            )}
                        </>
                    }
                    buttons={<PortalSubmitButton>Create Org</PortalSubmitButton>}
                    buttonAction={createOrg}
                    closeButton={<CloseButton onClick={toggleCreateOrg} />}
                />
            </Dialog>
        </>
    )
}

export default observer(CreateOrg)

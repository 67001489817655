import { Button, Dialog, makeStyles, TextField } from "@material-ui/core"
import { Client, toPrettyPhoneNum } from "@qlarity/common"
import { CloseButton } from "components/atoms/CloseButton"
import { observer } from "mobx-react"
import React from "react"
import ReactInputMask from "react-input-mask"
import { Clinician } from "store/modules/Auth"
import Store from "store/Store"
import PortalForm, { PortalSubmitButton } from "../PortalForm"

const useStyles = makeStyles((theme) => ({
    inherit: {
        textAlign: "inherit",
        fontWeight: "inherit",
        fontSize: "inherit",
        padding: 0,
        width: "100%",
        justifyContent: "flex-start",
    },
}))

const InviteClient: React.FC<{ clinician: Clinician; client?: Client }> = ({ clinician, client }) => {
    const classes = useStyles()
    // using React state because I was having trouble setting client defaults with Mobx :shrug:
    const [phoneNumber, setPhoneNumber] = React.useState(client ? toPrettyPhoneNum(client?.phoneNumber) : "")
    const [displayName, setDisplayName] = React.useState(client?.displayName ?? "")
    const [isInviteOpen, setIsInviteOpen] = React.useState(false)

    const toggleInviteClient = () => {
        if (!client) {
            setDisplayName("")
            setPhoneNumber("")
        }
        setIsInviteOpen(!isInviteOpen)
    }

    const sendInvite = async () => {
        if (displayName && phoneNumber) {
            await Store.auth.inviteClient(phoneNumber, displayName, clinician, client?.caseId)
        }

        toggleInviteClient()
    }

    return (
        <>
            <Button
                onClick={() => toggleInviteClient()}
                color={client ? "default" : "secondary"}
                variant={client ? "text" : "contained"}
                className={client ? classes.inherit : ""}
            >
                {client ? "Resend Invite" : "Invite Client"}
            </Button>

            <Dialog open={isInviteOpen} onClose={toggleInviteClient}>
                <PortalForm
                    title="Send Client Invite"
                    subtitle="This will send an invitation to your client"
                    form={
                        <>
                            <TextField label="Name" value={displayName} onChange={(e) => setDisplayName(e.target.value)} autoFocus required />
                            <ReactInputMask mask="(999) 999 9999" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required>
                                {() => <TextField label="Phone Number" type="tel" />}
                            </ReactInputMask>
                        </>
                    }
                    buttons={<PortalSubmitButton>Send Invite</PortalSubmitButton>}
                    buttonAction={sendInvite}
                    closeButton={<CloseButton onClick={toggleInviteClient} />}
                />
            </Dialog>
        </>
    )
}

export default observer(InviteClient)

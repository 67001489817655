import { PaletteOptions } from "@material-ui/core/styles/createPalette"

const palette: PaletteOptions = {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#F5F5F5", default: "#FFFFFF" },
    primary: {
        light: "#9BF0C8",
        main: "#67C097",
        // dark hard coded in overrides
        dark: "#008E6C",
        contrastText: "#000000",
    },
    secondary: {
        light: "#FFF",
        main: "#F5F5F5",
        dark: "#C7C7C7",
        contrastText: "#000",
    },
    error: {
        light: "#FF878F",
        main: "#E40A2F",
        dark: "#A2001B",
        contrastText: "#FFF",
    },
    text: {
        primary: "#000000",
        secondary: "rgba(36, 36, 34, 0.6)",
        disabled: "rgba(36, 36, 34, 0.25)",
        hint: "rgba(36, 36, 34, 0.4)",
    },
    info: {
        // graph default
        main: "rgba(72, 198, 149, 0.72)",
        dark: "rgba(128, 0, 255, 0.63)",
        light: "rgba(182, 214, 255, 0.8)",
    },
}
export default palette

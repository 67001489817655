import { defaultTheme } from "style"

export const defaultNivo = {
    background: "transparent",
    fontFamily: defaultTheme.typography.fontFamily,
    fontSize: 11,
    textColor: defaultTheme.palette.text.primary,
    axis: {
        domain: {
            line: {
                stroke: "transparent",
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: defaultTheme.palette.secondary.dark,
                strokeWidth: 1,
            },
            text: {
                ...defaultTheme.typography.overline,
            },
        },
        legend: {
            text: {
                ...defaultTheme.typography.caption,
            },
        },
    },
    grid: {
        line: {
            stroke: defaultTheme.palette.secondary.dark,
            strokeWidth: 1,
        },
    },
    legends: {
        text: {
            fill: defaultTheme.palette.text.secondary,
        },
    },
    labels: {
        text: {},
    },
}

import { Button, CircularProgress, Dialog, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core"
import { Client, PortalRole, UserStatus } from "@qlarity/common"
import { CloseButton } from "components/atoms/CloseButton"
import { observable } from "mobx"
import { observer } from "mobx-react"
import React from "react"
import { Clinician } from "store/modules/Auth"
import Store from "store/Store"
import PortalForm, { PortalFormProps, PortalSubmitButton } from "../PortalForm"

enum Stage {
    LOADING,
    HAS_CLIENTS,
    NO_CLIENTS,
}
class ArchiveClinicianState {
    @observable
    componentName = "ArchiveClinician"

    @observable
    isDialogOpen = false

    @observable
    selectedClinician?: Clinician

    @observable
    filteredOrgClinicians?: Clinician[]
    @observable
    displayName = ""

    @observable
    email = ""

    @observable
    clients?: Client[]

    @observable
    stage = Stage.LOADING
}

const useStyles = makeStyles((theme) => ({
    inherit: {
        textAlign: "inherit",
        fontWeight: "inherit",
        fontSize: "inherit",
        padding: 0,
        width: "100%",
        justifyContent: "flex-start",
    },
}))

const ArchiveClinician: React.FC<{ clinician: Clinician }> = ({ clinician }) => {
    const classes = useStyles()
    const state = React.useRef(new ArchiveClinicianState()).current

    const toggleArchiveClinician = React.useCallback(() => {
        state.isDialogOpen = !state.isDialogOpen
    }, [state.isDialogOpen])

    const handleArchiveClinician = React.useCallback(
        async (noClients?: boolean) => {
            let newClinicianId
            if (state.selectedClinician) {
                newClinicianId = state.selectedClinician.id
            } else if (state.displayName && state.email) {
                const { newUserId } = await Store.auth.invitePortalUser(state.email, clinician.orgId, PortalRole.CLINICIAN, state.displayName)
                newClinicianId = newUserId
            }
            if (!newClinicianId && !noClients) {
                throw new Error("new clinician id not figured out...")
            }

            await Store.auth.archiveUserReassignClients(clinician, newClinicianId, state.clients)
            state.stage = Stage.NO_CLIENTS
        },
        [clinician, state.clients, state.displayName, state.email, state.selectedClinician, state.stage]
    )

    React.useEffect(() => {
        let active = true
        const getFilteredClinicians = async () => {
            const result = await Store.auth.clinicianCollection.where("orgId", "==", clinician.orgId).get()
            if (active) {
                state.filteredOrgClinicians = result.docs
                    ?.map((c) => c.data())
                    .filter((c) => c.id !== clinician.id && c.status !== UserStatus.ARCHIVED)
                    .sort((a, b) => a.displayName.localeCompare(b.displayName))
            }
        }
        const getAllClients = async () => {
            const result = await Store.auth.clientCollection.where("clinicianId", "==", clinician.id).get()
            if (active) {
                if (!result.empty) {
                    state.stage = Stage.HAS_CLIENTS
                    state.clients = result.docs.map((client) => client.data())
                } else {
                    state.stage = Stage.NO_CLIENTS
                    await handleArchiveClinician(true)
                }
            }
        }
        const loadData = async () => {
            state.stage = Stage.LOADING
            await Promise.all([getFilteredClinicians(), getAllClients()])
        }
        if (state.isDialogOpen) {
            loadData()
        }
        return () => {
            active = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isDialogOpen])

    const render: PortalFormProps = {
        [Stage.LOADING]: {
            title: "Archive Clinician",
            subtitle: "Loading Data",
            form: <CircularProgress />,
            buttons: <></>,
            buttonAction: async () => {},
        },
        [Stage.HAS_CLIENTS]: {
            title: "Archive Clinician",
            subtitle: `You will need to reassign ${clinician.displayName}'s clients`,
            form: (
                <>
                    {state.filteredOrgClinicians ? (
                        <TextField
                            id="table-select"
                            select
                            label="Reassign to existing clinician"
                            value={state.selectedClinician?.id ?? ""}
                            SelectProps={{ MenuProps: { PaperProps: { square: true } } }}
                            onChange={(e) => {
                                state.displayName = ""
                                state.email = ""
                                state.selectedClinician = state.filteredOrgClinicians?.find((c) => c.id === e.target.value)
                            }}
                            onBlur={() => console.log("select focused")}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {state.filteredOrgClinicians?.map((c) => (
                                <MenuItem value={c.id} key={c.id}>
                                    {c.displayName}
                                </MenuItem>
                            )) || <CircularProgress />}
                        </TextField>
                    ) : (
                        <CircularProgress />
                    )}
                    <Typography variant="overline">Or reassign to new clinician</Typography>
                    <TextField
                        label="Name"
                        value={state.displayName}
                        onChange={(e) => {
                            state.selectedClinician = undefined
                            state.displayName = e.target.value
                        }}
                        onFocus={() => console.log("name focused")}
                    />
                    <TextField
                        label="Email"
                        type="email"
                        value={state.email}
                        onChange={(e) => {
                            state.selectedClinician = undefined
                            state.email = e.target.value
                        }}
                    />
                </>
            ),
            buttons: (
                <PortalSubmitButton disabled={!state.selectedClinician && (!state.displayName || !state.email)}>
                    Reassign Clients & Archive Clinician
                </PortalSubmitButton>
            ),
            buttonAction: handleArchiveClinician,
        },
        [Stage.NO_CLIENTS]: {
            title: "Clinician Archived",
            subtitle: "",
            form: <></>,
            buttons: <Button onClick={() => toggleArchiveClinician()}>Close</Button>,
            buttonAction: async () => {},
        },
    }[state.stage]
    return (
        <>
            <Button onClick={toggleArchiveClinician} color="default" variant={"text"} className={classes.inherit}>
                Archive Clinician
            </Button>
            <Dialog open={state.isDialogOpen} onClose={toggleArchiveClinician}>
                <PortalForm
                    closeButton={<CloseButton onClick={toggleArchiveClinician} />}
                    title={render.title}
                    subtitle={render.subtitle}
                    form={render.form}
                    buttons={render.buttons}
                    buttonAction={render.buttonAction}
                />
            </Dialog>
        </>
    )
}

export default observer(ArchiveClinician)

import { Client } from "@qlarity/common"
import { FirebaseRoutes } from "FirebaseRoutes"
import AccountHelp from "pages/AccountHelp"
import AdminPage from "pages/AdminPage"
import ClientPage from "pages/ClientPage"
import ClinicianPage from "pages/ClinicianPage"
import Error404Page from "pages/Error404Page"
import Home from "pages/Home"
import Login from "pages/Login"
import NewUserPage from "pages/NewUserPage"
import OrgPage from "pages/OrgPage"
import ProfilePage from "pages/ProfilePage"
import React from "react"
import { generatePath, Route, Switch } from "react-router-dom"
import { Clinician, Org } from "store/modules/Auth"
import { PrivateRoute } from "./PrivateRoute"

// TODO: Replace with relative routes fron React-Router V6
export const ROUTE_PATHS = {
    HOME: "/",
    LOGIN: "/login",
    ACCOUNT_HELP: "/account-help",
    NEW_USER: "/new-user",
    FIREBASE_ROUTE: "/auth/action",
    PROFILE: "/profile",
    ADMIN: "/admin",
    ORG: `/org/:orgId`,
    get CLINICIAN() {
        return `${ROUTE_PATHS.ORG}/clinician/:clinicianId`
    },
    get CLIENT() {
        return `${ROUTE_PATHS.CLINICIAN}/client/:clientCaseId`
    },
}
export const PATHS = {
    ROOT: () => "/*",
    HOME: () => ROUTE_PATHS.HOME,
    LOGIN: () => ROUTE_PATHS.LOGIN,
    ACCOUNT_HELP: () => ROUTE_PATHS.ACCOUNT_HELP,
    NEW_USER: () => ROUTE_PATHS.NEW_USER,
    RESET: () => `${ROUTE_PATHS.FIREBASE_ROUTE}?mode=resetPassword`,
    PROFILE: () => ROUTE_PATHS.PROFILE,
    ADMIN: () => ROUTE_PATHS.ADMIN,
    ORG: (org?: Org) => generatePath(ROUTE_PATHS.ORG, { orgId: org?.id }),
    CLINICIAN: (clinician?: Clinician) => generatePath(ROUTE_PATHS.CLINICIAN, { orgId: clinician?.orgId, clinicianId: clinician?.id }),
    CLIENT: (client?: Client) => generatePath(ROUTE_PATHS.CLIENT, { orgId: client?.orgId, clinicianId: client?.clinicianId, clientCaseId: client?.caseId }),
}

export const PortalRouter: React.FC = () => {
    return (
        <Switch>
            <Route path={ROUTE_PATHS.LOGIN}>
                <Login />
            </Route>
            <Route path={ROUTE_PATHS.ACCOUNT_HELP}>
                <AccountHelp />
            </Route>
            <Route path={ROUTE_PATHS.NEW_USER}>
                <NewUserPage />
            </Route>
            <Route path={ROUTE_PATHS.FIREBASE_ROUTE}>
                <FirebaseRoutes />
            </Route>
            <PrivateRoute path={ROUTE_PATHS.HOME} exact>
                <Home />
            </PrivateRoute>
            <PrivateRoute path={ROUTE_PATHS.PROFILE}>
                <ProfilePage />
            </PrivateRoute>
            <PrivateRoute path={ROUTE_PATHS.ADMIN} adminOnly>
                <AdminPage />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTE_PATHS.ORG} orgAdminOnly>
                <OrgPage />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTE_PATHS.CLINICIAN}>
                <ClinicianPage />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTE_PATHS.CLIENT}>
                <ClientPage />
            </PrivateRoute>
            <PrivateRoute path="*">
                <Error404Page />
            </PrivateRoute>
        </Switch>
    )
}

import { PortalRole } from "@qlarity/common"
import { autorun } from "mobx"
import Error404Page from "pages/Error404Page"
import React from "react"
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom"
import { PATHS } from "Router"
import Store from "store/Store"

interface PrivateRouteProps {
    adminOnly?: boolean
    orgAdminOnly?: boolean
}

export const PrivateRoute: React.FC<RouteProps & PrivateRouteProps> = ({ children, adminOnly, orgAdminOnly, ...rest }) => {
    const history = useHistory()
    React.useEffect(
        () =>
            autorun(() => {
                if (!Store.auth.isLoggedIn) {
                    history.push(PATHS.LOGIN())
                }
            }),
        [history]
    )

    if (!Store.auth.isLoggedIn) {
        return <Redirect to={PATHS.LOGIN()} />
    }

    if (adminOnly && Store.auth.role !== PortalRole.ADMIN) {
        console.log("permission denied")
        return <Error404Page />
    } else if (orgAdminOnly && Store.auth.role !== PortalRole.ORG_ADMIN && Store.auth.role !== PortalRole.ADMIN) {
        console.log("permission denied")
        return <Error404Page />
    } else {
        return <Route {...rest}>{children}</Route>
    }
}

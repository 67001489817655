// eslint-disable-next-line
import { ThemeProvider } from "@material-ui/core"
import CssBaseLine from "@material-ui/core/CssBaseline"
import Debug from "components/organisms/Debug/Debug"
import MainLayout from "components/organisms/Layout/MainLayout"
import LogRocket from "logrocket"
import setupLogRocketReact from "logrocket-react"
import { observer } from "mobx-react"
import "mobx-react-lite/batchingForReactDom"
import React from "react"
import ReactDOM from "react-dom"
import { ToastComponent } from "store/modules/Toasts"
import Store from "store/Store"
import boot from "./boot"
import * as serviceWorker from "./serviceWorker"

LogRocket.init("qlarity/qlarity", {
    release: process.env.REACT_APP_VERSION,
    dom: {
        baseHref: "https://qlarity-prod.firebaseapp.com/",
    },
})
// after calling LogRocket.init()
setupLogRocketReact(LogRocket)

async function main() {
    await boot()

    const rootElement = document.getElementById("root")
    const App = observer(() => (
        <ThemeProvider theme={Store.appContext.state.theme}>
            <CssBaseLine />
            <ToastComponent toastStore={Store.toasts} />
            <MainLayout />
            <Debug />
        </ThemeProvider>
    ))

    ReactDOM.render(<App />, rootElement)

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister()
}

main()

import AppContext from "./modules/AppContext"
import Auth from "./modules/Auth"
import ClientStore from "./modules/ClientStore"
import Toasts from "./modules/Toasts"

export class Store {
    appContext: AppContext
    auth: Auth
    clientStore: ClientStore
    toasts: Toasts

    constructor() {
        this.appContext = new AppContext()
        this.auth = new Auth()
        this.clientStore = new ClientStore()
        this.toasts = new Toasts()
    }

    async init() {
        const promises = [this.appContext.init(this), this.auth.init(this), this.clientStore.init(this), this.toasts.init(this)]
        return Promise.all(promises)
    }
}

export default new Store()

import { Button, Dialog, TextField, Typography } from "@material-ui/core"
import { Client } from "@qlarity/common"
import { CloseButton } from "components/atoms/CloseButton"
import { observer } from "mobx-react"
import React from "react"
import Store from "store/Store"
import PortalForm, { PortalSubmitButton } from "../PortalForm"

const RedeemPoints: React.FC<{ client: Client }> = ({ client }) => {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false)
    const [points, setPoints] = React.useState<number | string>("")

    const redeemPoints = async () => {
        if (!client.points || typeof points === "string") {
            return
        }
        if (points > client.points) {
            throw new Error(`Cannot redeem points more than what you have!`)
        }

        await Store.auth.redeemPoints(client, points)
        setModalOpen(false)
    }
    const handlePoints = (e: React.ChangeEvent<HTMLInputElement>) => {
        const onlyDigits = e.target.value.replace(/\D/g, "")
        setPoints(onlyDigits ? Number(onlyDigits) : "")
    }

    return (
        <>
            <Button onClick={() => setModalOpen(true)} color="primary">
                Redeem
            </Button>

            <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                <PortalForm
                    closeButton={<CloseButton onClick={() => setModalOpen(false)} />}
                    title="Redeem"
                    subtitle=" If you are a counselor or physician, deduct the appropriate number of points to unlock incentives and track the progress of your
                        patient."
                    form={
                        <>
                            <TextField
                                label="Enter amount"
                                type="number"
                                onChange={handlePoints}
                                value={points ?? ""}
                                autoFocus
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        redeemPoints()
                                    }
                                }}
                            />
                            <Typography variant="body2">
                                {client.displayName} will have
                                <b> {client.points && typeof points !== "string" ? client.points - points : client.points ?? 0} </b>
                                points remaining
                            </Typography>
                        </>
                    }
                    buttons={<PortalSubmitButton disabled={!client.points || !points || points > client.points}>Redeem</PortalSubmitButton>}
                    buttonAction={redeemPoints}
                />
            </Dialog>
        </>
    )
}

export default observer(RedeemPoints)

import MoreVert from "@material-ui/icons/MoreVert"
import { DropdownMenu, UserStatus } from "@qlarity/common"
import { observer } from "mobx-react"
import React from "react"
import Store from "store/Store"
import CreateOrg from "../dialogs/CreateOrg"

const OrgMenu: React.FC<{ orgId: string; status?: UserStatus.ACTIVE | UserStatus.ARCHIVED }> = ({ orgId, status }) => {
    return (
        <DropdownMenu
            noBackground
            options={[
                { name: "<CreateOrg /> open to edit org details", element: <CreateOrg isEdit orgId={orgId} /> },
                status === UserStatus.ARCHIVED
                    ? {
                          name: "Reactivate",
                          callback: () => Store.auth.reactivateOrg(orgId),
                      }
                    : { name: "Archive", callback: () => Store.auth.archiveOrg(orgId) },
            ]}
        >
            <MoreVert color="inherit" />
        </DropdownMenu>
    )
}

export default observer(OrgMenu)

import { Client, ContentfulApi } from "@qlarity/common"
import { ContentfulClientApi } from "contentful"
import { computed, observable } from "mobx"
import { Store } from "../Store"
import Base from "./Base"

export default class ClientStore extends Base {
    @observable
    modules?: ContentfulApi.QModule[]

    @observable
    currentClient?: Client

    @observable
    contentfulClient?: ContentfulClientApi

    @computed
    get currentModule() {
        return this.currentClient ? this.findCurrentModule(this.currentClient) : undefined
    }
    findCurrentModule = (client: Client) => {
        if (!client.progress) {
            return
        }
        return Object.values(client.progress).reduce((max, curr) => {
            const maxMax = Math.max(max.trainingCompleted?.seconds ?? 0, max.practiceCompleted?.seconds ?? 0)
            const currMax = Math.max(curr.trainingCompleted?.seconds ?? 0, curr.practiceCompleted?.seconds ?? 0)
            return maxMax > currMax ? max : curr
        })
    }

    findLastActivity = (client: Client) => {
        const lastCheckin = this.findLastMood(client)?.timestamp
        const lastMod = this.findCurrentModule(client)
        if (!lastMod) {
            return lastCheckin?.toDate()
        }
        const lastProgress = (lastMod.trainingCompleted ?? 0) > (lastMod.practiceCompleted ?? 0) ? lastMod.trainingCompleted : lastMod.practiceCompleted

        const latest = (lastCheckin ?? 0) > (lastProgress ?? 0) ? lastCheckin : lastProgress
        return latest?.toDate()
    }

    findLastMood = (client: Client) => {
        return client.checkins?.reduce((max, curr) => (max.timestamp > curr.timestamp ? max : curr))
    }

    getShortId = (client: Client) =>
        client.caseId
            .slice(0, 6)
            .toUpperCase()
            .match(/(.{3})/g)
            ?.join("-")

    init = async (store: Store) => {
        if (!process.env.REACT_APP_CONTENTFUL_SPACE || !process.env.REACT_APP_CONTENTFUL_TOKEN) {
            throw new Error("Contenful ENVs not found ")
        }
        this.contentfulClient = ContentfulApi.getClient(process.env.REACT_APP_CONTENTFUL_SPACE, process.env.REACT_APP_CONTENTFUL_TOKEN)
        this.modules = await ContentfulApi.getAllQModules(this.contentfulClient)
    }
}

import MoreVert from "@material-ui/icons/MoreVert"
import { DropdownMenu, PortalRole, UserStatus } from "@qlarity/common"
import { observer } from "mobx-react"
import React from "react"
import { Clinician } from "store/modules/Auth"
import Store from "store/Store"
import ArchiveClinician from "../dialogs/ArchiveClinician"

const ClinicianMenu: React.FC<{ clinician: Clinician }> = ({ clinician }) => {
    return (
        <DropdownMenu
            noBackground
            options={[
                {
                    name: "Resend invite",
                    callback: async () => {
                        const result = await Store.auth.invitePortalUser(clinician.email, clinician.orgId, clinician.role, clinician.displayName)
                        console.log(result)
                    },
                },
                {
                    name: clinician.role === PortalRole.CLINICIAN ? "Make Org Admin" : "Remove Admin Privileges",
                    callback: () => Store.auth.togglePortalUserOrgAdmin(clinician.id, clinician.role),
                },
                clinician.status === UserStatus.ARCHIVED
                    ? {
                          name: "Reactivate Clinician",
                          callback: () => Store.auth.reactivateUser(clinician),
                      }
                    : {
                          // Name use only as key when Element is sent
                          name: "Name is set in <ArchiveClinician />",
                          element: <ArchiveClinician clinician={clinician} />,
                      },
            ]}
        >
            <MoreVert color="inherit" />
        </DropdownMenu>
    )
}

export default observer(ClinicianMenu)

import MoreVert from "@material-ui/icons/MoreVert"
import { Client, DropdownMenu, UserStatus } from "@qlarity/common"
import { observer } from "mobx-react"
import React from "react"
import { Clinician } from "store/modules/Auth"
import Store from "store/Store"
import InviteClient from "../dialogs/InviteClient"

const ClientMenu: React.FC<{ client: Client; clinician: Clinician }> = ({ client, clinician }) => {
    return (
        <DropdownMenu
            noBackground
            options={[
                {
                    // Name use only as key when Element is sent
                    name: "Name is set in <InviteClient />",
                    element: <InviteClient clinician={clinician} client={client} />,
                },

                client.status === UserStatus.ARCHIVED
                    ? { name: "Reactivate", callback: () => Store.auth.reactivateClient(client, clinician) }
                    : { name: "Archive", callback: () => Store.auth.archiveClient(client, clinician) },
            ]}
        >
            <MoreVert color="inherit" />
        </DropdownMenu>
    )
}

export default observer(ClientMenu)

import { Button, Dialog, TextField } from "@material-ui/core"
import { CloseButton } from "components/atoms/CloseButton"
import { observable } from "mobx"
import { observer } from "mobx-react"
import React from "react"
import Store from "store/Store"
import PortalForm, { PortalSubmitButton } from "../PortalForm"

class InviteQlarityAdminState {
    @observable
    componentName = "InviteQlarityAdmin"

    @observable
    adminEmail = ""

    @observable
    adminDisplayName = ""

    @observable
    inviteGlobalAdmin = false
}

const InviteQlarityAdmin: React.FC = () => {
    const state = React.useRef(new InviteQlarityAdminState()).current

    const toggleInviteQlarityAdmin = () => {
        state.adminEmail = ""
        state.adminDisplayName = ""
        state.inviteGlobalAdmin = !state.inviteGlobalAdmin
    }

    const createAdmin = async () => {
        await Store.auth.inviteGlobalAdmin(state.adminDisplayName, state.adminEmail)
        toggleInviteQlarityAdmin()
    }

    return (
        <>
            <Button onClick={toggleInviteQlarityAdmin} color="secondary">
                Invite Qlarity Admin
            </Button>

            <Dialog open={state.inviteGlobalAdmin} onClose={toggleInviteQlarityAdmin}>
                <PortalForm
                    closeButton={<CloseButton onClick={toggleInviteQlarityAdmin} />}
                    title="Invite Qlarity Admin"
                    subtitle="Warning: This creates an administrator with full rights"
                    form={
                        <>
                            <TextField
                                label="Name of new Qlarity admin"
                                value={state.adminDisplayName}
                                onChange={(e) => (state.adminDisplayName = e.target.value)}
                                required
                                autoFocus
                            />
                            <TextField
                                label="Email of new Qlarity admin"
                                value={state.adminEmail}
                                onChange={(e) => (state.adminEmail = e.target.value)}
                                required
                                type="email"
                            />
                        </>
                    }
                    buttons={<PortalSubmitButton>Create Qlarity Admin</PortalSubmitButton>}
                    buttonAction={createAdmin}
                />
            </Dialog>
        </>
    )
}

export default observer(InviteQlarityAdmin)

import { IconButton, IconButtonProps, makeStyles, Theme } from "@material-ui/core"
import Close from "@material-ui/icons/Close"
import clsx from "clsx"
import React, { CSSProperties } from "react"

interface CloseButtonProps {
    className?: string
    style?: CSSProperties
    onClick: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
    closeAtom: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
}))

export const CloseButton: React.FC<CloseButtonProps & IconButtonProps> = (props) => {
    const classes = useStyles()
    const className = clsx([classes.closeAtom, props.className])

    return (
        <IconButton onClick={props.onClick} aria-label="close" className={className}>
            <Close />
        </IconButton>
    )
}

import { Overrides } from "@material-ui/core/styles/overrides"
import { ComponentsProps } from "@material-ui/core/styles/props"

export const overrides: Overrides = {
    MuiButton: {
        root: {
            padding: "12px 32px",
            borderRadius: 100,
        },
        contained: {
            boxShadow: "none",
        },
        label: {
            textTransform: "capitalize",
        },
    },
    MuiLink: {
        root: {
            color: "#008E6C",
        },
    },
    MuiTextField: {
        root: {
            "& .Mui-focused": {
                color: "#000",
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: "#000",
            },
        },
    },
    MuiDialog: {
        paperScrollPaper: {
            display: "block",
        },
    },
    MuiDialogContent: {
        root: {
            display: "grid",
            gridGap: 16,
            paddingTop: 32,
            maxWidth: 283,
            textAlign: "left",
        },
    },
    MuiPaper: {
        rounded: {
            borderRadius: 24,
        },
    },
    MuiIconButton: {
        root: {
            color: "#000",
        },
    },
}
export const props: ComponentsProps = {
    MuiButton: {
        variant: "contained",
        color: "primary",
    },
    MuiDialog: {
        fullWidth: true,
    },
    MuiMenu: {
        PaperProps: {
            square: true,
        },
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "right",
        },
    },
}

import { Button } from "@material-ui/core"
import React from "react"
import { Client } from "@qlarity/common"

export const CsvButton: React.FC<{ clients: Client[] | undefined }> = ({ clients }) => {
    const onCsvReader = () => {
        const result = clients?.map((client) => {
            let formattedProgress = []
            for (let moduleId in client.progress) {
                const mod = client.progress[moduleId]
                formattedProgress.push({
                    ...mod,
                    practiceCompletedDate: mod.practiceCompleted?.toDate().toLocaleDateString() ?? "",
                    practiceCompletedTime: mod.practiceCompleted?.toDate().toLocaleTimeString() ?? "",
                    trainingCompletedDate: mod.trainingCompleted?.toDate().toLocaleDateString() ?? "",
                    trainingCompletedTime: mod.trainingCompleted?.toDate().toLocaleTimeString() ?? "",
                })
            }
            // sort by name
            formattedProgress.sort((a, b) => a.name.localeCompare(b.name))

            return {
                ...client,
                progress: formattedProgress,
            }
        })
        const progresses = result?.map((x) => x.progress)
        const userIds = result?.map((x) => x.caseId)
        const checkedIns = result?.map((x) => x.checkins)
        const download = function (data: string, name: string) {
            const blob = new Blob([data], { type: "text/csv" })
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.setAttribute("hidden", "")
            a.setAttribute("href", url)
            a.setAttribute("download", `${name}-${new Date().toLocaleString()}.csv`)
            document.body.appendChild(a)
            a.click()
        }

        if (userIds && checkedIns && progresses) {
            let csvList: string[][] = []
            let userList: any = []
            let csvHappiness = []
            const numOfModules = 13
            let headersForCsv = ["User Id", "Amount of Checkins"]
            const headers = [
                "Module",
                "Module Training Completion Date",
                "Module Training Completion Time",
                "# of Trainings",
                "Module Practice Completion Date",
                "Module Practice Completion Time",
                "# of Practices",
            ]
            for (let i = 0; i < numOfModules - 1; i++) {
                headersForCsv.push(...headers)
            }
            csvList.push(headersForCsv)

            result?.forEach((user) => {
                const row: string[] = [user.caseId, user.checkins?.length.toString() ?? "0"]
                user.progress.forEach((p) => {
                    row.push(
                        p.name?.toString() ?? "",
                        p.trainingCompletedDate,
                        p.trainingCompletedTime,
                        p.numTrainings?.toString() ?? "",
                        p.practiceCompletedDate,
                        p.practiceCompletedTime,
                        p.numPractices?.toString() ?? ""
                    )
                })
                csvList.push(row)
            })

            userIds.forEach((_, i) => {
                let startIndex = i * numOfModules
                let stopIndex = startIndex + numOfModules
                csvList?.push(userList.slice(startIndex, stopIndex))
            })

            csvHappiness.push(`User Id, Date, Time, Happiness Rating`)

            userIds.forEach((userId, i) => {
                checkedIns[i]?.forEach((checkIn) => {
                    csvHappiness.push(
                        `${userId.slice(0, 3)}-${userId.slice(
                            3,
                            6
                        )}, ${checkIn.timestamp.toDate().toDateString()}, ${checkIn.timestamp.toDate().toLocaleTimeString()}, ${checkIn.moodName}`
                    )
                })
            })
            download(csvList.map((row) => row.join(",")).join("\n"), "qlarity-progress")
            download(csvHappiness.join("\n"), "qlarity-checkins")
        }
    }

    return (
        <Button color={"secondary"} variant={"contained"} onClick={onCsvReader}>
            Download CSV
        </Button>
    )
}

import Login from "pages/Login"
import NewUserPage from "pages/NewUserPage"
import ResetPage from "pages/ResetPage"
import React from "react"
import { useLocation } from "react-router-dom"

export const FirebaseRoutes: React.FC = () => {
    const queryParams = new URLSearchParams(useLocation().search)
    // make room for future firebase routes like changing email or other sign ins :shrug:
    switch (queryParams.get("mode")) {
        case "resetPassword":
            return <ResetPage oobCode={queryParams.get("oobCode")} />
        case "signIn":
            return <NewUserPage />
        default:
            return <Login />
    }
}

import { firebaseConfig } from "@qlarity/common"
import * as Firebase from "firebase/app"
import Store from "store/Store"
/* import styles */
import "style/fonts/fonts.css"

export default async function boot() {
    Firebase.initializeApp(firebaseConfig.prod)
    await Store.init()
}

import { TypographyOptions } from "@material-ui/core/styles/createTypography"
import "typeface-lato"

const typography: TypographyOptions = {
    htmlFontSize: 16,
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
        fontFamily: "Lato",
        fontSize: "64px",
        fontWeight: 900,
        fontStyle: "normal",
        lineHeight: "64px",
        // letterSpacing: "-1px",
    },
    h2: {
        fontFamily: "Lato",
        fontSize: "48px",
        fontWeight: 900,
        fontStyle: "normal",
        lineHeight: "56px",
        // letterSpacing: "-0.5px",
    },
    h3: {
        fontFamily: "Lato",
        fontSize: "36px",
        fontWeight: 900,
        fontStyle: "normal",
        lineHeight: "48px",
        letterSpacing: "-0.01em",
    },
    h4: {
        fontFamily: "Lato",
        fontSize: "32px",
        fontWeight: "normal",
        fontStyle: "italic",
        lineHeight: "40px",
        // letterSpacing: "0.25px",
    },
    h5: {
        fontFamily: "Lato",
        fontSize: "28px",
        fontWeight: 900,
        fontStyle: "normal",
        lineHeight: "32px",
        letterSpacing: "0.02em",
    },
    h6: {
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: "24px",
        // letterSpacing: "0.25px",
    },
    body1: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "24px",
        letterSpacing: "0.02em",
    },
    button: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: "0.03em",
    },
    subtitle1: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: "24px",
        // letterSpacing: "0.15px",
    },
    body2: {
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "0.02em",
    },
    subtitle2: {
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: "18px",
        letterSpacing: "0.02em",
    },
    overline: {
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: "bold",
        fontStyle: "normal",
        lineHeight: 1.33,
        letterSpacing: "2px",
    },
    caption: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.04em",
    },
}

export default typography
